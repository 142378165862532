export function forceFileDownload(base64, name) 
{  
  const url = window.URL.createObjectURL(new Blob([base64ToArrayBuffer(base64)]));
  const link = document.createElement('a');
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
}
export function base64ToArrayBuffer(base64) {
    var binary_string = atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}